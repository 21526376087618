import { Tooltip, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { CheckCircle, ErrorRounded } from "@material-ui/icons";
import { get } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Booking, ProductInstance } from "../types";
import { getStyle } from "../utils";
import { StatusColorContext } from "./contexts";
import { translateStatus } from "./StatusField";

interface Props {
  resource: { id: number; status: string };
}

interface MyChipProps {
  customResourceName?: string;
  resource: { id: number; status: string };
  resourceName: string;
  avatarCharacter?: string;
  style?: object;
  avatarAction?: string;
  tooltip?: React.ReactNode;
  isEOT?: boolean;
}
const MyChip: React.FC<MyChipProps> = ({
  customResourceName,
  resource,
  resourceName,
  avatarCharacter,
  style,
  avatarAction,
  tooltip,
  isEOT,
}) => {
  const status = translateStatus(resource.status);

  const label = `${resource.id} ${
    customResourceName ? customResourceName : status
  }`;

  const initial = avatarCharacter ?? resourceName.substring(0, 2).toUpperCase();
  const { colors } = React.useContext(StatusColorContext);

  return (
    <Box display="inline-block" style={{ padding: 1 }}>
      <Link
        to={`/${resourceName}/${resource.id}/show`}
        style={{
          ...getStyle(customResourceName ? "revised" : resource.status, colors),
          textDecoration: "none",
          display: "flex",
          padding: "2px",
          borderRadius: "16px",
          fontSize: "0.7rem",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
            color: "#333",
            fontSize: "0.6rem",
            fontWeight: "bold",
            borderRadius: "50%",
            padding: "5px",
          }}
        >
          {initial}
        </Box>

        <Box
          style={{
            padding: "3px 5px ",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Box>

        {tooltip && (
          <Box
            style={{
              color: customResourceName ? "#fff" : "#333",
              fontSize: "0.6rem",
              fontWeight: "bold",
            }}
          >
            {tooltip}
          </Box>
        )}

        {avatarAction && (
          <Box
            style={{
              backgroundColor: "white",
              color: "#333",
              fontSize: "0.6rem",
              fontWeight: "bold",
              borderRadius: "50%",
              padding: "5px",
            }}
          >
            {avatarAction}
          </Box>
        )}

        {isEOT && (
          <Box
            style={{
              backgroundColor: "red",
              color: "#ffff",
              fontSize: "0.6rem",
              fontWeight: "bold",
              borderRadius: "50%",
              padding: "5px",
            }}
          >
            EOT
          </Box>
        )}
      </Link>
    </Box>
  );
};

export const BookingChip: React.FC<{ resource: Booking }> = ({
  resource,
  ...rest
}) => {
  const hasIssues = resource.booking_issues?.length > 0;
  const hasRevisions = resource.revisions?.length > 0;
  const isNew = resource.status === "new";

  // Function to determine the appropriate icon based on booking issue statuses
  const renderTooltipIcon = () => {
    if (!hasIssues) return null; // No issues, no icon needed

    return (
      <Tooltip
        title={
          <React.Fragment>
            {resource.booking_issues.map((issue, index) => (
              <Typography variant="body2" key={issue.id} color="inherit">
                {index + 1}. {issue.status} - {issue.description}
              </Typography>
            ))}
          </React.Fragment>
        }
      >
        {
          // Check if all booking issues have the same status
          resource.booking_issues.every((issue) => issue.status === "open") ? (
            <ErrorRounded color="error" />
          ) : resource.booking_issues.every(
              (issue) => issue.status === "accepted"
            ) ? (
            <CheckCircle color="primary" />
          ) : resource.booking_issues.every(
              (issue) => issue.status === "rejected"
            ) ? (
            <CheckCircle color="inherit" />
          ) : (
            // If mixed statuses exist, show a generic error icon
            <ErrorRounded color="inherit" />
          )
        }
      </Tooltip>
    );
  };

  return (
    <MyChip
      isEOT={resource.is_eot}
      tooltip={renderTooltipIcon()} // Apply tooltip with icon if issues exist
      customResourceName={isNew && hasRevisions ? "REVISE" : undefined} // Set "REVISE" only if status is new and there are revisions
      resource={resource}
      resourceName="bookings"
      avatarCharacter="BK"
      {...rest}
    />
  );
};

export const AllocationChip: React.FC<Props> = ({ resource, ...rest }) => (
  <MyChip
    resource={resource}
    resourceName="allocations"
    avatarCharacter="AL"
    {...rest}
  />
);

export const DeliveryChip: React.FC<Props> = ({ resource, ...rest }) => (
  <MyChip
    resource={resource}
    resourceName="deliveries"
    avatarCharacter="DL"
    {...rest}
  />
);

export const ProductInstanceChip: React.FC<{ resource: ProductInstance }> = ({
  resource,
  ...rest
}) => {
  const backgroundColor = get(
    resource,
    "product.business_unit.color_hex",
    "#aaa"
  );

  return (
    <MyChip
      avatarAction={resource.is_consigned ? "CS" : ""}
      resource={resource}
      resourceName="product-instances"
      avatarCharacter="ST"
      style={{ backgroundColor }}
      {...rest}
    />
  );
};
