import { unset } from "lodash";
import { FC } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import SelectOrTextInput from "../../components/fields/SelectOrTextInput";
import HospitalRoomInput from "../../components/inputs/HospitalRoomInput";
import { convertJsToSql } from "../../utils/date";
import bookings from "./";
import useStyles from "./shared/useStyles";

const BookingCreateToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton
      // disabled={props.pristine}
      transform={(data) => {
        // unset the hospital, or procedure name if they are null
        if (!data.procedure?.name) {
          unset(data, "procedure");
        } else {
          unset(data, "procedure_id");
        }

        if (!data.surgeon?.full_name) {
          unset(data, "surgeon");
        } else {
          unset(data, "surgeon_id");
        }

        return {
          ...data,
          items: [
            ...(data.implantitems ?? []),
            ...(data.instrumentitems ?? []),
          ],
        };
      }}
    />
  </Toolbar>
);

// const CustomDateComp = () => {
//   const isEOT = useField("is_eot").input.value;

//   return (
//     <DateTimeInput
//       source="date"
//       disabled={isEOT}
//       initialValue={isEOT ? new Date(new Date().setUTCHours(0, 0, 0, 0)) : null}
//       parse={(value: any) => convertJsToSql(value)}
//     />
//   );
// };

export const BookingCreate: FC<CreateProps> = (props) => {
  const classes = useStyles();

  // // #region initialise surgeons
  // const { data: surgeons }: { data?: Surgeon[] } = useQueryWithStore({
  //   type: "getList",
  //   resource: "surgeons",
  //   payload: {
  //     pagination: { perPage: 1000 },
  //     sort: { field: "name", order: "asc" },
  //     filter: { active: true },
  //   },
  // });
  // const [surgeonsList, setSurgeonsList] = useState<ChoiceProps[]>();
  // useEffect(() => {
  //   if (!surgeons) return;

  //   setSurgeonsList(
  //     surgeons.map((surgeon) => ({
  //       id: `${surgeon.id}`,
  //       name: surgeon.full_name,
  //     }))
  //   );
  // }, [surgeons]);
  // // #endregion

  // // #region initialise consultants
  // const { data: consultants }: { data?: Consultant[] } = useQueryWithStore({
  //   type: "getList",
  //   resource: "surgeons",
  //   payload: {
  //     pagination: { perPage: 1000 },
  //     sort: { field: "name", order: "asc" },
  //     filter: { active: true },
  //   },
  // });
  // const [consultantList, setConsultantList] = useState<ChoiceProps[]>();
  // useEffect(() => {
  //   if (!consultants) return;

  //   setConsultantList(
  //     consultants.map((item) => ({
  //       id: `${item.id}`,
  //       name: item.full_name,
  //     }))
  //   );
  // }, [consultants]);
  // // #endregion

  return (
    <>
      <CrudBreadcrumb
        resourceName={bookings.name}
        mode="create"
        resourceId={""}
      />
      <Create {...props} className={classes.root}>
        <SimpleForm toolbar={<BookingCreateToolbar />}>
          <BooleanInput source="is_eot" label="Is EOT?" />
          <ReferenceInput
            source="consultant_id"
            reference="consultants"
            sort={{ field: "full_name", order: "asc" }}
          >
            <AutocompleteInput optionText="full_name" />
          </ReferenceInput>
          <DateTimeInput
            source="date"
            parse={(value: any) => convertJsToSql(value)}
          />
          <SelectOrTextInput
            source="surgeon_id"
            textSource="surgeon.full_name"
            label="Surgeon"
            choices={{
              resource: "surgeons",
              optionString: "full_name",
              optionValue: "id",
            }}
          />
          <SelectOrTextInput
            source="procedure_id"
            textSource="procedure.name"
            label="Procedure"
            choices={{
              resource: "procedures",
              optionString: "name",
              optionValue: "id",
            }}
          />
          <HospitalRoomInput source="hospital_room_id" />
          <TextInput source="remarks" />
          <h2>Instruments</h2>
          <ArrayInput source="instrumentitems" label="Instrument Items">
            <SimpleFormIterator className={classes.instrument}>
              <ReferenceInput
                label="Instrument Name"
                source="product_id"
                reference="products"
                sort={{ field: "name", order: "ASC" }}
                filter={{ type: "instrument" }}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <NumberInput source="quantity" label="Quantity" min="1" />
            </SimpleFormIterator>
          </ArrayInput>
          <h2>Implants</h2>
          <ArrayInput source="implantitems" label="Implant Items">
            <SimpleFormIterator className={classes.instrument}>
              <ReferenceInput
                label="Implant Name"
                source="product_id"
                reference="products"
                sort={{ field: "name", order: "ASC" }}
                filter={{ type: "implant" }}
                perPage={10000}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <NumberInput source="quantity" label="Quantity" min="1" />
            </SimpleFormIterator>
          </ArrayInput>
          <h2>Additional Items</h2>
          <TextInput
            multiline
            source="additional_items"
            placeholder="Enter the additional items that are not in the Instrument and Implant lists"
          />
        </SimpleForm>
      </Create>
    </>
  );
};
